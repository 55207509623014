export const useBrands = async (deliveryKey = 'all-brands-kv') => {
  const localePath = useLocalePath()
  const query = {
    locale: localePath(''),
    vse: useRoute().query.vse,
    contentId: useRoute().query.contentId,
    deliveryKey: deliveryKey,
  }

  const { data: allBrands } = await useFetch('/api/cms/getAllBrands', {
    query,
    getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
  })

  return {
    allBrands,
  }
}
